<template>
  <div class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >
        <b-form-datepicker
          v-model="createDateFr"
          class="el-def"
          placeholder="기간(Fr)"
        />
        <label class="ml-50 mr-50">~</label>
        <b-form-datepicker
          v-model="createDateTo"
          class="el-def mr-1"
          placeholder="기간(To)"
        />
        <b-button
          variant="primary"
          class="btn-search w-50 mr-1"
          @click.prevent="searchAskHistory()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50 mr-1"
          @click.prevent="setQueryDate(3)"
        >
          3개월
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50"
          @click.prevent="setQueryDate(6)"
        >
          6개월
        </b-button>
      </b-col>
    </b-row>
    <b-row style="margin-top: 15px;">
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-radio-group
          v-model="query.askType"
        >
          <b-form-radio value="1">
            1:1 문의 내역 ({{ askHistoryCount.askCount }})건
          </b-form-radio>
          <b-form-radio value="2">
            Call 문의 내역 ({{ askHistoryCount.callCount }})건
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>

    <b-card
      no-body
      class="mt-2"
    >
      <b-table
        v-show="query.askType === '1'"
        primary-key="Id"
        :items="askHistories"
        :fields="askColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>

      <b-table
        v-show="query.askType === '2'"
        ref="refCallTable"
        primary-key="Id"
        :items="callHistories"
        :fields="callColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onSelectedCall"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      v-if="query.askType === '2'"
      class="mt-2"
    >
      <b-row>
        <b-col
          md="12"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetCall()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규 등록</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="saveCall()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="deleteCall()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>

      <validation-observer ref="formRules">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mt-1"
          >
            <h6>문의 내용</h6>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <validation-provider
              #default="{ errors }"
              name="문의 내용"
              rules="required"
            >
              <b-form-textarea
                v-model="callData.content"
                placeholder="문의 내용 입력"
                rows="4"
                maxlength="500"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
            class="mt-1"
          >
            <h6>답변 내용</h6>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-textarea
              v-model="callData.answer"
              placeholder="답변 내용 입력"
              rows="4"
              maxlength="500"
            />
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, watch, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const query = ref({
      userId: null,
      askType: '1',
      searchDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      searchDateTo: dayjs().format('YYYY-MM-DD'),
    })

    watch(() => props.userId, newVal => {
      query.value.userId = newVal
    })

    watch(query.value, () => {
      fetchAskHistories()
    })

    watch(() => [query.value.userId, query.value.searchDateFr, query.value.searchDateTo], () => {
      fetchAskHistoryCount()
    })

    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs
    const toast = useToast()
    const refCallTable = ref(null)

    const askHistoryCount = ref({
      askCount: 0,
      callCount: 0,
    })

    const askHistories = ref([])
    const callHistories = ref([])

    const askColumns = [
      {
        key: 'createDate', label: '문의 일자', sortable: true, thStyle: { width: '12%' },
      },
      { key: 'title', label: '문의 제목', thStyle: { width: '20%' } },
      { key: 'content', label: '문의 내용', thStyle: { width: '20%' } },
      { key: 'answer', label: '답변 내용', thStyle: { width: '30%' } },
      { key: 'answerUserName', label: '담당자', thStyle: { width: '10%' } },
      {
        key: 'status', label: '처리여부', sortable: true, thStyle: { width: '8%' },
      },
    ]

    const callColumns = [
      {
        key: 'createDate', label: '문의 일자', sortable: true, thStyle: { width: '12%' },
      },
      { key: 'content', label: '문의 내용', thStyle: { width: '35%' } },
      { key: 'answer', label: '답변 내용', thStyle: { width: '35%' } },
      { key: 'answerUserName', label: '담당자', thStyle: { width: '10%' } },
      {
        key: 'status', label: '처리여부', sortable: true, thStyle: { width: '8%' },
      },
    ]

    const callData = ref({
      id: null,
      content: null,
      answer: null,
    })

    const createDateFr = ref(dayjs().add(-3, 'month').format('YYYY-MM-DD'))
    const createDateTo = ref(dayjs().format('YYYY-MM-DD'))

    const setQueryDate = term => {
      createDateFr.value = dayjs().add(-term, 'month').format('YYYY-MM-DD')
      createDateTo.value = dayjs().format('YYYY-MM-DD')
    }

    const searchAskHistory = () => {
      query.value.searchDateFr = createDateFr.value
      query.value.searchDateTo = createDateTo.value
    }

    const fetchAskHistoryCount = () => {
      if (query.value.userId) {
        axios.get(`/fa/user/ask-hists/${query.value.userId}/count`, {
          params: {
            searchDateFr: query.value.searchDateFr,
            searchDateTo: query.value.searchDateTo,
          },
        })
          .then(rs => {
            askHistoryCount.value = rs.data
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const fetchAskHistories = () => {
      if (query.value.userId) {
        axios.post('/fa/user/ask-hists', {
          userId: query.value.userId,
          askType: query.value.askType,
          searchDateFr: query.value.searchDateFr === '' ? null : query.value.searchDateFr,
          searchDateTo: query.value.searchDateTo === '' ? null : query.value.searchDateTo,
        })
          .then(rs => {
            if (query.value.askType === '1') askHistories.value = rs.data
            else if (query.value.askType === '2') {
              callHistories.value = rs.data

              resetCall()
            }
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const resetCall = () => {
      callData.value = {
        id: null,
        content: null,
        answer: null,
      }

      refs.formRules.reset()
      refCallTable.value.clearSelected()
    }

    const saveCall = () => {
      const validPromise = refs.formRules.validate()

      validPromise.then(valid => {
        if (valid) {
          const { id } = callData.value

          if (id) updateCall()
          else createCall()
        }
      })
    }

    const createCall = () => {
      if (query.value.userId) {
        axios.post('/fa/ask-history/call', {
          userId: query.value.userId,
          content: callData.value.content,
          answer: callData.value.answer,
        })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Call 문의 내역 등록 완료',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            fetchAskHistoryCount()
            fetchAskHistories()
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Call 문의 내역 등록에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Call 문의를 등록 할 고객을 선택해주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const updateCall = () => {
      axios.put(`/fa/ask-history/call/${callData.value.id}`, {
        content: callData.value.content,
        answer: callData.value.answer,
      })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Call 문의 내역 수정 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          fetchAskHistoryCount()
          fetchAskHistories()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Call 문의 내역 수정에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const deleteCall = () => {
      if (callData.value.id) {
        axios.delete(`/fa/ask-history/call/${callData.value.id}`, {
        })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Call 문의 내역 삭제 완료',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            fetchAskHistoryCount()
            fetchAskHistories()
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Call 문의 내역 삭제에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 Call 문의 내역을 선택해주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const onSelectedCall = items => {
      const item = items[0]

      if (item) {
        callData.value.id = item.id
        callData.value.content = item.content
        callData.value.answer = item.answer
      }
    }

    return {
      query,
      required,
      toast,
      refCallTable,
      askHistoryCount,
      askHistories,
      callHistories,
      askColumns,
      callColumns,
      fetchAskHistoryCount,
      fetchAskHistories,
      callData,
      resetCall,
      saveCall,
      createCall,
      updateCall,
      deleteCall,
      onSelectedCall,
      setQueryDate,
      createDateFr,
      createDateTo,
      searchAskHistory,
    }
  },
}
</script>
