var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center justify-content-end",
              attrs: { cols: "12", md: "6" },
            },
            [
              _c("b-form-datepicker", {
                staticClass: "el-def",
                attrs: { placeholder: "기간(Fr)" },
                model: {
                  value: _vm.createDateFr,
                  callback: function ($$v) {
                    _vm.createDateFr = $$v
                  },
                  expression: "createDateFr",
                },
              }),
              _c("label", { staticClass: "ml-50 mr-50" }, [_vm._v("~")]),
              _c("b-form-datepicker", {
                staticClass: "el-def mr-1",
                attrs: { placeholder: "기간(To)" },
                model: {
                  value: _vm.createDateTo,
                  callback: function ($$v) {
                    _vm.createDateTo = $$v
                  },
                  expression: "createDateTo",
                },
              }),
              _c(
                "b-button",
                {
                  staticClass: "btn-search w-50 mr-1",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.searchActHistory()
                    },
                  },
                },
                [_vm._v(" 조회 ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "w-50 mr-1",
                  attrs: { variant: "outline-dark" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setQueryDate(3)
                    },
                  },
                },
                [_vm._v(" 3개월 ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "w-50",
                  attrs: { variant: "outline-dark" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setQueryDate(6)
                    },
                  },
                },
                [_vm._v(" 6개월 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticStyle: { "margin-top": "15px" } },
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-start mb-1 mb-md-0",
              attrs: { cols: "12", md: "8" },
            },
            [
              _c(
                "b-form-radio-group",
                {
                  model: {
                    value: _vm.query.viewTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "viewTypes", $$v)
                    },
                    expression: "query.viewTypes",
                  },
                },
                [
                  _c("b-form-radio", { attrs: { value: "1" } }, [
                    _vm._v(
                      " 약품 정보 (" +
                        _vm._s(
                          _vm.actHistoryCount.drugCommentCount +
                            _vm.actHistoryCount.drugAskCount
                        ) +
                        ")건 "
                    ),
                  ]),
                  _c("b-form-radio", { attrs: { value: "2" } }, [
                    _vm._v(
                      " 메디컬트렌드 (" +
                        _vm._s(_vm.actHistoryCount.medicalCount) +
                        ")건 "
                    ),
                  ]),
                  _c("b-form-radio", { attrs: { value: "5" } }, [
                    _vm._v(
                      " 웹세미나 (" +
                        _vm._s(_vm.actHistoryCount.seminarCount) +
                        ")건 "
                    ),
                  ]),
                  _c("b-form-radio", { attrs: { value: "3" } }, [
                    _vm._v(
                      " 설문조사 (" +
                        _vm._s(_vm.actHistoryCount.surveyCount) +
                        ")건 "
                    ),
                  ]),
                  _c("b-form-radio", { attrs: { value: "4" } }, [
                    _vm._v(
                      " Weekly Choice (" +
                        _vm._s(_vm.actHistoryCount.pollCount) +
                        ")건 "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        {
          staticClass: "mt-2",
          attrs: { "no-body": "" },
          scopedSlots: _vm._u([
            {
              key: "cell(title)",
              fn: function (data) {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                        "white-space": "nowrap",
                      },
                    },
                    [_vm._v(" " + _vm._s(data.value) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.query.viewTypes === "1" || _vm.query.viewTypes === "2"
            ? _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  "primary-key": "Id",
                  items: _vm.actHistories,
                  fields: _vm.commentTypeColumns,
                  responsive: "",
                  "sticky-header": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(title)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                              },
                            },
                            [_vm._v(" " + _vm._s(data.value) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(createDate)",
                      fn: function (data) {
                        return [
                          _c("span", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    data.value,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3313967333
                ),
              })
            : _vm._e(),
          _vm.query.viewTypes === "3" || _vm.query.viewTypes === "4"
            ? _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  "primary-key": "Id",
                  items: _vm.actHistories,
                  fields: _vm.surveyTypeColumns,
                  responsive: "",
                  "sticky-header": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(createDate)",
                      fn: function (data) {
                        return [
                          _c("span", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    data.value,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(title)",
                      fn: function (data) {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                              },
                            },
                            [_vm._v(" " + _vm._s(data.value) + " ")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2275136805
                ),
              })
            : _vm._e(),
          _vm.query.viewTypes === "5"
            ? _c("b-table", {
                staticClass: "position-relative",
                attrs: {
                  "primary-key": "Id",
                  items: _vm.actHistories,
                  fields: _vm.seminarTypeColumns,
                  responsive: "",
                  "sticky-header": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(createDate)",
                      fn: function (data) {
                        return [
                          _c("span", { staticClass: "text-nowrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("$dateFormatter")(
                                    data.value,
                                    "YYYY-MM-DD HH:mm"
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  986344585
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }