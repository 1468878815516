<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="12"
            md="4"
            class="mb-1 mb-md-0"
          >
            <b-form-input
              v-model="query.filterStr"
              placeholder="검색어 (ID, 이름, 휴대폰번호, 면허번호, 근무처명)"
              maxlength="50"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              고객 수 [{{ totalRecords }}] 명
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-if="internal"
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refMemberTable"
        primary-key="userId"
        :items="fetchMembers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedMember"
      >
        <template #cell(fullName)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(phoneNumber)="data">
          <span class="text-nowrap">
            {{ data.value ? data.value : '-' }}
          </span>
        </template>
        <template #cell(gender)="data">
          <span class="text-nowrap">
            {{ data.value ? data.value : '-' }}
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="5"
        per-page="5"
        align="center"
      />
    </b-card>

    <b-card
      no-body
    >
      <b-tabs
        pills
        class="m-2"
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">상세정보</span>
          </template>
          <member-info-view :user-id="memberUserId" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="Share2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">참여 내역 조회</span>
          </template>
          <member-act-history :user-id="memberUserId" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="GiftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">포인트 내역 조회</span>
          </template>
          <member-point-history :user-id="memberUserId" />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">1:1 문의 내역 관리</span>
          </template>
          <member-ask-history :user-id="memberUserId" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import {
  ref, onMounted, watch, getCurrentInstance,
} from '@vue/composition-api'
import { BTab, BTabs } from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import MemberInfoView from './MemberInfoView.vue'
import MemberActHistory from './MemberActHistory.vue'
import MemberPointHistory from './MemberPointHistory.vue'
import MemberAskHistory from './MemberAskHistory.vue'

export default {
  components: {
    BTab,
    BTabs,
    MemberInfoView,
    MemberActHistory,
    MemberPointHistory,
    MemberAskHistory,
  },
  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()

    const internal = getCurrentInstance().proxy.$internal

    // Query Data
    const query = ref({
      hcpWorkType: null,
      hcpSpecType: null,
      hcpMedicalDept: null,
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const memberUserId = ref(0)
    const refMemberTable = ref(null)
    const sortBy = ref("userId")
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const tableColumns = [
      // { key: "No", label: "No.", sortable: true },
      {
        key: "userId", label: "Id", sortable: true, thStyle: { width: '7%' },
      },
      {
        key: "fullName", label: "이름", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "hcpLicenseNumber", label: "의사면허번호", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "phoneNumber", label: "휴대전화번호", sortable: true, thStyle: { width: '10%' },
      },
      {
        key: "hcpWorkPlace", label: "근무처명", sortable: true, thStyle: { width: '12%' },
      },
      {
        key: "gender", label: "성별", sortable: true, thStyle: { width: '7%' },
      },
      { key: "hcpWorkAddress", label: "지역", sortable: true },
      {
        key: "hcpMedicalDept", label: "진료과", sortable: true, thStyle: { width: '10%' },
      },
    ]

    watch([currPage], () => {
      refMemberTable.value.refresh()
    })

    const refetchData = () => {
      refMemberTable.value.refresh()
    }

    const onRowSelectedMember = items => {
      const item = items[0]

      if (item && item.userId) {
        memberUserId.value = item.userId
      }
    }
    // Main Table Config End.

    // Set Codes
    const codes = ref({
      hcpWorkType: [],
      hcpSpecType: [],
      hcpMedicalDept: [],
    })

    const fetchCodes = () => {
      axios
        .get("/fa/user/codes")
        .then(rs => {
          const { hcpWorkType, hcpSpecType, hcpMedicalDept } = rs.data

          codes.value.hcpWorkType = hcpWorkType
          codes.value.hcpSpecType = hcpSpecType
          codes.value.hcpMedicalDept = hcpMedicalDept
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "코드 데이터를 불러오는데 실패하였습니다.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    }
    // Set Codes End.

    const fetchMembers = (ctx, callback) => {
      axios
        .post("/fa/user/list", {
          search: query.value,
          sort: {
            predicate: sortBy.value,
            reverse: isSortDesc.value,
          },
          pagination: {
            number: currPage.value,
            count: 5,
          },
        })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord

          memberUserId.value = 0
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "데이터 조회에 실패하였습니다.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    }

    const excelDownload = () => {
      axios.post("/fa/user/excel", {
        search: query.value,
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      }, {
        responseType: "blob",
      })
        .then(rs => {
          const mimeType = {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }

          const blob = new Blob([rs.data], mimeType)

          saveAs(blob, "고객관리_리스트.xlsx")
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "데이터 조회에 실패하였습니다.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    }

    // 내부망에서만 다운로드 가능
    // const excelDownload = async () => {
    //   const workbook = new ExcelJS.Workbook()
    //   const worksheet = workbook.addWorksheet("sheet1")

    //   worksheet.columns = [
    //     { header: "Id", key: "userId", width: 10 },
    //     { header: "이름", key: "fullName", width: 10 },
    //     { header: "의사면허번호", key: "hcpLicenseNumber", width: 15 },
    //     { header: "휴대전화번호", key: "phoneNumber", width: 15 },
    //     { header: "근무처명", key: "hcpWorkPlace", width: 15 },
    //     { header: "성별", key: "gender", width: 10 },
    //     { header: "지역", key: "hcpWorkAddress", width: 30 },
    //     { header: "진료과", key: "hcpMedicalDept", width: 10 },
    //   ]

    //   refMemberTable.value.localItems.map(e => worksheet.addRow({
    //     userId: e.userId,
    //     fullName: e.fullName,
    //     hcpLicenseNumber: e.hcpLicenseNumber,
    //     phoneNumber: e.phoneNumber,
    //     hcpWorkPlace: e.hcpWorkPlace,
    //     gender: e.gender,
    //     hcpWorkAddress: e.hcpWorkAddress,
    //     hcpMedicalDept: e.hcpMedicalDept,
    //   }))

    //   worksheet.eachRow({ includeEmpty: true }, (row, number) => {
    //     row.eachCell({ includeEmpty: true }, cell => {
    //       if (number === 1) {
    //         // eslint-disable-next-line no-param-reassign
    //         cell.font = {
    //           bold: true,
    //         }

    //         // eslint-disable-next-line no-param-reassign
    //         cell.fill = {
    //           type: 'pattern',
    //           pattern: 'solid',
    //           fgColor: { argb: 'D3D3D3' },
    //         }
    //       }

    //       // eslint-disable-next-line no-param-reassign
    //       cell.border = {
    //         top: { style: 'thin' },
    //         left: { style: 'thin' },
    //         bottom: { style: 'thin' },
    //         right: { style: 'thin' },
    //       }
    //     })
    //   })

    //   const mimeType = {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   }

    //   const buffer = await workbook.xlsx.writeBuffer()

    //   const blob = new Blob([buffer], mimeType)

    //   saveAs(blob, "고객관리_리스트.xlsx")
    // }

    return {
      query,
      codes,
      tableColumns,
      refMemberTable,
      sortBy,
      isSortDesc,
      memberUserId,
      refetchData,
      currPage,
      totalRecords,
      onRowSelectedMember,
      fetchMembers,
      excelDownload,
      internal,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
