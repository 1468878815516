<!-- 고객 포인트 내역 조회 -->
<template>
  <div class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="누적 적립 포인트"
          label-for="point-total-point"
        >
          <b-form-input
            id="point-total-point"
            :value="pointInfo.totalPoint | $numberFormatter('P')"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="사용 포인트"
          label-for="point-use-point"
        >
          <b-form-input
            id="point-use-point"
            :value="pointInfo.usePoint | $numberFormatter('P')"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="소멸 포인트"
          label-for="point-extinction-point"
        >
          <b-form-input
            id="point-extinction-point"
            :value="pointInfo.extinctionPoint | $numberFormatter('P')"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="미사용 포인트"
          label-for="point-not-use-point"
        >
          <b-form-input
            id="point-not-use-point"
            :value="pointInfo.notUsePoint | $numberFormatter('P')"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="2"
      >
        <b-form-group
          label="포인트 수여 회수"
          label-for="point-manage"
        >
          <cleave
            id="point-manage"
            v-model="savePoint"
            placeholder="숫자만 입력 가능"
            :options="cleave.number"
            maxlength="8"
            class="form-control"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <b-form-group
          label="내용"
          label-for="member-full-name2"
        >
          <b-form-input
            id="member-full-name2"
            v-model="pointDescription"
            placeholder="내용을 입력하세요."
            maxlength="50"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="4"
        md="3"
        class="mb-50"
      >
        <b-button
          variant="primary"
          class="mt-0 mt-md-2 mr-1"
          @click="setPoint('+')"
        >
          <span>수여</span>
        </b-button>
        <b-button
          variant="primary"
          class="mt-0 mt-md-2 mr-1"
          @click="setPoint('-')"
        >
          <span>회수</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-checkbox-group
          v-model="query.viewTypes"
          :options="viewTypes"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >
        <b-form-datepicker
          v-model="createDateFr"
          class="el-def"
          placeholder="기간(Fr)"
        />
        <label class="ml-50 mr-50">~</label>
        <b-form-datepicker
          v-model="createDateTo"
          class="el-def mr-1"
          placeholder="기간(To)"
        />
        <b-button
          variant="primary"
          class="btn-search w-50 mr-1"
          @click.prevent="searchPointHistory()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50 mr-1"
          @click.prevent="setQueryDate(3)"
        >
          3개월
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50"
          @click.prevent="setQueryDate(6)"
        >
          6개월
        </b-button>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mt-2"
    >
      <b-table
        primary-key="Id"
        :items="pointHistories"
        :fields="tableColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(pointDate)="data">
          <span>
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
        <template #cell(categoryName)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(description)="data">
          <span class="d-block text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(plusMinusName)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(amount)="data">
          <span>{{ data.value | $numberFormatter }}</span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    watch(() => props.userId, newVal => {
      if (newVal === 0) {
        pointInfo.value = { ...pointInfoInitState }
        pointHistories.value = []
      } else query.value.userId = newVal
    })

    // const instance = getCurrentInstance()
    // const bvModal = instance.proxy.$bvModal

    const savePoint = ref(0)
    const pointDescription = ref(null)
    const cleave = ref({
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: 'P',
        tailPrefix: true,
      },
    })

    const pointInfoInitState = {
      totalPoint: 0,
      usePoint: 0,
      extinctionPoint: 0,
      notUsePoint: 0,
    }

    const pointInfo = ref({ ...pointInfoInitState })

    const toast = useToast()

    // Query Data
    const viewTypes = [
      { text: '적립', value: '1' },
      { text: '사용', value: '2' },
      { text: '소멸', value: '3' },
    ]

    const query = ref({
      userId: null,
      viewTypes: [1, 2, 3, 4],
      searchDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      searchDateTo: dayjs().format('YYYY-MM-DD'),
    })

    const createDateFr = ref(dayjs().add(-3, 'month').format('YYYY-MM-DD'))
    const createDateTo = ref(dayjs().format('YYYY-MM-DD'))

    watch(query.value, () => {
      fetchPointInfo()
      fetchPointHistories()
    })

    const setQueryDate = term => {
      createDateFr.value = dayjs().add(-term, 'month').format('YYYY-MM-DD')
      createDateTo.value = dayjs().format('YYYY-MM-DD')
    }

    const searchPointHistory = () => {
      query.value.searchDateFr = createDateFr.value
      query.value.searchDateTo = createDateTo.value
    }
    // Query Data End.

    // Main Table Config
    const pointHistories = ref([])
    const tableColumns = [
      {
        key: 'pointDate', label: '일시', sortable: true, thStyle: { width: '15%' },
      },
      {
        key: 'categoryName', label: '구분', sortable: true, thStyle: { width: '10%' },
      },
      { key: 'description', label: '내용', sortable: true },
      {
        key: 'amount', label: '포인트', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'plusMinusName', label: '적립/사용', sortable: true, thStyle: { width: '10%' },
      },
    ]
    // Main Table Config End.

    const fetchPointInfo = () => {
      if (query.value.userId) {
        axios.get(`/fa/user/point/${query.value.userId}`)
          .then(rs => {
            pointInfo.value = rs.data
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const fetchPointHistories = () => {
      if (query.value.userId) {
        axios.post('/fa/user/point-hists', {
          userId: query.value.userId,
          viewTypes: query.value.viewTypes.join('|'),
          searchDateFr: query.value.searchDateFr === '' ? null : query.value.searchDateFr,
          searchDateTo: query.value.searchDateTo === '' ? null : query.value.searchDateTo,
        })
          .then(rs => {
            const { items } = rs.data

            pointHistories.value = items
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const setPoint = type => {
      if (query.value.userId === null) {
        toast({
          component: ToastificationContent,
          props: {
            title: '포인트를 수여/회수할 고객을 선택해주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (savePoint.value.replace('P', '') === '0') {
        toast({
          component: ToastificationContent,
          props: {
            title: '포인트를 입력해주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (savePoint.value.replace('P', '').search(/[^0-9]/g) !== -1) {
        toast({
          component: ToastificationContent,
          props: {
            title: '포인트에 숫자 외에 값을 입력하지마세요.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (pointDescription.value === null) {
        toast({
          component: ToastificationContent,
          props: {
            title: '내용을 입력해주세요.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else {
        axios.post('/fa/user/point', {
          userId: query.value.userId,
          savePoint: type + savePoint.value.replace('P', ''),
          description: pointDescription.value,
        })
          .then(() => {
            savePoint.value = 0
            pointDescription.value = null

            fetchPointInfo()
            fetchPointHistories()
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '포인트 수여/회수 중 오류가 발생했습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    return {
      query,
      viewTypes,
      pointHistories,
      setQueryDate,
      tableColumns,
      fetchPointHistories,
      fetchPointInfo,
      pointInfo,
      cleave,
      savePoint,
      pointDescription,
      setPoint,
      createDateFr,
      createDateTo,
      searchPointHistory,
    }
  },
}
</script>
