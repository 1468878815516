<!-- 고객 참여 내역 조회 -->
<template>
  <div class="mt-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-end"
      >
        <b-form-datepicker
          v-model="createDateFr"
          class="el-def"
          placeholder="기간(Fr)"
        />
        <label class="ml-50 mr-50">~</label>
        <b-form-datepicker
          v-model="createDateTo"
          class="el-def mr-1"
          placeholder="기간(To)"
        />
        <b-button
          variant="primary"
          class="btn-search w-50 mr-1"
          @click.prevent="searchActHistory()"
        >
          조회
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50 mr-1"
          @click.prevent="setQueryDate(3)"
        >
          3개월
        </b-button>
        <b-button
          variant="outline-dark"
          class="w-50"
          @click.prevent="setQueryDate(6)"
        >
          6개월
        </b-button>
      </b-col>
    </b-row>
    <b-row style="margin-top: 15px;">
      <b-col
        cols="12"
        md="8"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <b-form-radio-group
          v-model="query.viewTypes"
        >
          <b-form-radio value="1">
            약품 정보 ({{ actHistoryCount.drugCommentCount + actHistoryCount.drugAskCount }})건
          </b-form-radio>
          <b-form-radio value="2">
            메디컬트렌드 ({{ actHistoryCount.medicalCount }})건
          </b-form-radio>
          <b-form-radio value="5">
            웹세미나 ({{ actHistoryCount.seminarCount }})건
          </b-form-radio>
          <b-form-radio value="3">
            설문조사 ({{ actHistoryCount.surveyCount }})건
          </b-form-radio>
          <b-form-radio value="4">
            Weekly Choice ({{ actHistoryCount.pollCount }})건
          </b-form-radio>
        </b-form-radio-group>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="mt-2"
    >
      <b-table
        v-if="query.viewTypes === '1' || query.viewTypes === '2'"
        primary-key="Id"
        :items="actHistories"
        :fields="commentTypeColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>

      <b-table
        v-if="query.viewTypes === '3' || query.viewTypes === '4'"
        primary-key="Id"
        :items="actHistories"
        :fields="surveyTypeColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-table
        v-if="query.viewTypes === '5'"
        primary-key="Id"
        :items="actHistories"
        :fields="seminarTypeColumns"
        responsive
        sticky-header
        class="position-relative"
      >
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>
      <template #cell(title)="data">
        <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          {{ data.value }}
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const query = ref({
      userId: null,
      viewTypes: '1',
      searchDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      searchDateTo: dayjs().format('YYYY-MM-DD'),
    })

    const createDateFr = ref(dayjs().add(-3, 'month').format('YYYY-MM-DD'))
    const createDateTo = ref(dayjs().format('YYYY-MM-DD'))

    watch(() => props.userId, newVal => {
      query.value.userId = newVal
    })

    const toast = useToast()

    const actHistoryCount = ref({
      drugCommentCount: 0,
      drugAskCount: 0,
      medicalCount: 0,
      seminarCount: 0,
      surveyCount: 0,
      pollCount: 0,
    })

    // Query Data
    const actHistories = ref([])

    const fetchActHistoryCount = () => {
      if (query.value.userId) {
        axios.get(`/fa/user/act-hists/${query.value.userId}/count`, {
          params: {
            searchDateFr: query.value.searchDateFr,
            searchDateTo: query.value.searchDateTo,
          },
        })
          .then(rs => {
            actHistoryCount.value = rs.data
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    const fetchActHistories = () => {
      if (query.value.userId) {
        axios.post('/fa/user/act-hists', {
          userId: query.value.userId,
          viewTypes: query.value.viewTypes,
          searchDateFr: query.value.searchDateFr === '' ? null : query.value.searchDateFr,
          searchDateTo: query.value.searchDateTo === '' ? null : query.value.searchDateTo,
        })
          .then(rs => {
            actHistories.value = rs.data
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: '데이터 조회에 실패하였습니다.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    watch(query.value, () => {
      fetchActHistories()
    })

    watch(() => [query.value.userId, query.value.searchDateFr, query.value.searchDateTo], () => {
      fetchActHistoryCount()
    })

    const setQueryDate = term => {
      // query.value.createDateFr = dayjs().add(-term, 'month').format('YYYY-MM-DD')
      // query.value.createDateTo = dayjs().format('YYYY-MM-DD')
      createDateFr.value = dayjs().add(-term, 'month').format('YYYY-MM-DD')
      createDateTo.value = dayjs().format('YYYY-MM-DD')
    }

    const searchActHistory = () => {
      query.value.searchDateFr = createDateFr.value
      query.value.searchDateTo = createDateTo.value
    }
    // Query Data End.

    // Main Table Config
    const commentTypeColumns = [
      {
        key: 'createDate', label: '참여 일시', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'contentType', label: '참여 구분', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'actType', label: '참여 유형', sortable: true, thStyle: { width: '10%' },
      },
      { key: 'title', label: '참여 컨텐츠', thStyle: { width: '30%' } },
      { key: 'comment', label: '작성 댓글', thStyle: { width: '28%' } },
      { key: 'deleteType', label: '댓글 삭제 여부', thStyle: { width: '10%' } },
    ]

    const surveyTypeColumns = [
      {
        key: 'createDate', label: '참여 일시', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'contentType', label: '참여 구분', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'status', label: '진행 상태', sortable: true, thStyle: { width: '10%' },
      },
      { key: 'title', label: '참여 컨텐츠' },
    ]

    const seminarTypeColumns = [
      {
        key: 'createDate', label: '참여 일시', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'contentType', label: '참여 구분', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'clientName', label: '제약사(포털명)', sortable: true, thStyle: { width: '10%' },
      },
      { key: 'title', label: '참여 컨텐츠' },
    ]
    // Main Table Config End.

    return {
      query,
      actHistories,
      setQueryDate,
      commentTypeColumns,
      surveyTypeColumns,
      seminarTypeColumns,
      fetchActHistoryCount,
      fetchActHistories,
      actHistoryCount,
      createDateFr,
      createDateTo,
      searchActHistory,
    }
  },
}
</script>
